import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectionService } from './device-detection.service';

@Injectable({
  providedIn: 'root'
})
export class FormVisibilityService {
  private isFormSubject = new BehaviorSubject<boolean>(false); // Para el estado del formulario de consentimiento
  isForm$ = this.isFormSubject.asObservable();

  private showFormSubject = new BehaviorSubject<boolean>(true);
  showForm$ = this.showFormSubject.asObservable();

  private previousOrientation: string = '';

  constructor(private deviceDetectionService: DeviceDetectionService) {
    this.detectDeviceOrientation();
  }

  private detectDeviceOrientation() {
    const isMobile = this.deviceDetectionService.isMobileDevice();
    
    const currentOrientation = this.deviceDetectionService.getDeviceOrientation();
    this.previousOrientation = currentOrientation;

    // Inicializar el estado del formulario
    if (isMobile && currentOrientation === 'portrait') {
      this.showFormSubject.next(false);
      if(this.showFormSubject.value){
        console.log('que valor tiene 1: ', this.isForm$);
        alert('Utilice el dispositivo en modo horizontal para visualizar el formulario.');
      }
    }

    // Escuchar cambios de orientación
    this.deviceDetectionService.onOrientationChange().subscribe((orientation) => {
      if (isMobile) {
        if (orientation === 'landscape') {
          this.showFormSubject.next(true);
        } else if (orientation === 'portrait' && this.previousOrientation !== 'portrait') {
          this.showFormSubject.next(false);
          if(this.showFormSubject.value){
            console.log('que valor tiene 2: ', this.isForm$);
            alert('Utilice el dispositivo en modo horizontal para visualizar el formulario.');
          }
        }
      }
      this.previousOrientation = orientation;
    });
  }

  // Método para establecer si el componente actual es un formulario de consentimiento
  setIsForm(isForm: boolean) {
    this.isFormSubject.next(isForm);
  }
}