import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    
  }

  // Método para verificar si el dispositivo es móvil
  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|iphone|ipad|iPod|windows phone/i.test(userAgent);
  }

  // Método para obtener la orientación del dispositivo (portrait o landscape)
  getDeviceOrientation(): string {
    if (window.screen.orientation) {
      return window.screen.orientation.type.includes('portrait') ? 'portrait' : 'landscape';
    }
    return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
  }

  // Método para detectar cambios en la orientación usando un Observable
  onOrientationChange(): Observable<string> {
    return fromEvent(window, 'resize').pipe(
      map(() => window.innerHeight > window.innerWidth ? 'portrait' : 'landscape')
    );
  }
}